.info_text {
  align-self: flex-start;
  color: #cdcdcd;

  font-family: var(--default-font-family);
}

.loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
}

p.no_data {
  padding: 10px 0;
}

.tokens_container {
  width: 100%;
  max-height: 350px;
  margin-top: 32px;

  overscroll-behavior: none;
  overflow-y: scroll;
  color: #b16bec;

  scrollbar-width: thin;
  scrollbar-color: #b16bec transparent;
}

.tokens_container::-webkit-scrollbar {
  width: 6px;
}

.tokens_container::-webkit-scrollbar-track {
  background: transparent;
}

.tokens_container::-webkit-scrollbar-thumb {
  background: #b16bec;
}

.tokens_container::-webkit-scrollbar-thumb:hover {
  background: rgba(177, 107, 236, 0.8);
}

.styled_button {
  width: 100%;
  margin-top: 32px;
}

.token {
  font-family: var(--default-font-family);
}
