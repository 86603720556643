.wallet_box {
  display: flex;
}

.wallet {
  width: 200px;
  margin: 0 32px;
}

@media (max-width: 38rem) {
  .wallet_box {
    flex-direction: column;
  }

  .wallet {
    margin: 16px 0;
  }
}
