.styled_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1rem;
  color: var(--title-text-color);
  font-weight: bold;
  font-size: 14px;

  font-family: var(--default-font-family);
  font-style: normal;
  line-height: 16px;
  background: var(--accent-gradient);
  border: none;

  border-radius: 6px;
  outline: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.styled_button:focus {
  background-position: 99% 0;
}

.styled_button:hover {
  color: var(--background-color);
  background-position: 99% 0;
}

.styled_button:disabled {
  background: rgba(48, 64, 79, 1);
}
