.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.content_container {
  display: flex;
  justify-content: space-between;
  width: 78%;
  max-width: 1200px;
  margin-top: 80px;
  margin-bottom: 40px;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 64px;
}

.left_title {
  margin: 0 0 6px 0;
  color: #e8eaed;
  font-weight: 600;
  font-size: 24px;
  font-family: var(--default-font-family);
  font-style: normal;
  line-height: 32px;
}

.link_box {
  display: flex;
  flex-direction: column;
}

.link {
  align-self: flex-start;

  margin: 6px 0;
  color: #da4dfc;
  font-weight: 600;
  font-size: 14px;
  font-family: var(--default-font-family);
  font-style: normal;
  line-height: 20px;
}

.text_link {
  color: #da4dfc;
  font-weight: bold;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.disclaimer_title {
  margin-bottom: 40px;
  color: #e8eaed;
  font-weight: 600;
  font-size: 24px;
  font-family: var(--default-font-family);
  font-style: normal;
  line-height: 32px;
}

.disclaimer_text_box {
  max-height: 60vh;
  overflow-y: scroll;

  scrollbar-width: thin;
  scrollbar-color: #b16bec transparent;
}

.disclaimer_text_box::-webkit-scrollbar {
  width: 6px;
}

.disclaimer_text_box::-webkit-scrollbar-track {
  background: transparent;
}

.disclaimer_text_box::-webkit-scrollbar-thumb {
  background: #b16bec;
}

.disclaimer_text {
  max-width: 736px;
  color: #e8eaed;
  font-weight: normal;
  font-size: 14px;
  font-family: var(--default-font-family);
  font-style: normal;
  line-height: 24px;
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78%;
  height: 80px;
  border-top: 2px solid #353945;
}

.copyright_text {

  color: #e8eaed;
  font-weight: normal;
  font-size: 12px;
  font-family: var(--default-font-family);
  font-style: normal;
  line-height: 20px;
}

@media (max-width: 57rem) {
  .content_container {
    flex-direction: column;
    align-items: center;
  }

  .disclaimer_title {
    align-self: center;
  }

  .left {
    margin: 0;
  }

  .left_title {
    align-self: center;
  }

  .link {
    align-self: center;
  }
}

.faq {
  width: 78%;
  max-width: 1200px;
  margin-top: 60px;
  line-height: 24px;
}

.faq_title {
  margin-bottom: 2rem;
  color: var(--title-text-color);
  font-weight: 700;
  font-size: 2rem;
}

.faq_sub_title, .faq_redemption_sub_title {
  margin-bottom: 1rem;
  color: var(--title-text-color);
  font-weight: 600;
  font-size: 1.5rem;
}

.faq_p_margin, .faq_redemption_sub_title {
  margin: 1rem 0;
}

.faq ol {
  margin: 1rem 0 2rem 2rem;
}

.faq ol li {
  margin: 0.5rem 0;
  list-style-position: outside;
  list-style-type: decimal;
}

.faq ul {
  margin: 1rem 0 1rem 2rem;
}

.faq ul li {
  margin: 0.5rem 0;
  list-style-position: outside;
  list-style-type: disc;
}
