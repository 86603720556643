/* .styled_modal { */
/*  position: absolute; */
/*  top: 50%; */
/*  left: 50%; */
/*  overflow-y: auto; */
/*  z-index: 1000; */
/*  transform: translate(-50%, -50%); */
/*  max-width: 544px; */
/*  width: 90vw; */
/*  max-height: 90vh; */
/*  padding: 2rem; */
/*  background-color: var(--background-color); */
/*  border-radius: 20px; */
/*  outline: 0; */
/*  box-shadow: 0 64px 64px -48px rgba(0, 0, 0, 0.12); */
/* } */

.styled_modal {

  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 544px;

  margin: 30px;
  background: #141416;

  border-radius: 12px;

  outline: none;
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.modal_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 32px;
}

.modal_header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}

.modal_title {
  margin: 0;
  color: var(--title-text-color);
  font-weight: bold;
  font-size: 32px;
  font-style: normal;
  line-height: 40px;

  letter-spacing: -0.01em;
}

.modal_close_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  color: #b16bec;
  font-size: 14px;
  line-height: 1em;
  background: transparent;
  border: 2px solid #b16bec;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}

.modal_close_button:hover,
.modal_close_button:focus {
  color: #000;
  background: #b16bec;
}
