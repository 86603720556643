.styled_checkbox {
  position: relative;
  display: flex;
  cursor: pointer;
  user-select: none;
}

.checkbox_input {
  position: absolute;
  opacity: 0;
}

.inner_wrapper {
  position: relative;
  width: 100%;
  padding: 24px 20px 24px 64px;
  border-radius: 20px;
  transition: all 0.4s ease-in-out;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 16px;
  display: inline-flex;
  width: 24px;
  height: 24px;
  background: transparent;
  border: 2px solid #e8eaed;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: all 0.4s ease-in-out;
}

.label {
  color: var(--title-text-color);
  font-weight: 600;
  line-height: 1.4em;
}

.styled_checkbox:hover .inner_wrapper,
.checkbox_input:focus ~ .inner_wrapper {
  background: rgba(12, 31, 48, 0.3);
}

.checkbox_input:checked ~ .inner_wrapper {
  background: rgba(12, 31, 48, 0.75);
}

.checkbox_input:checked ~ .inner_wrapper .checkmark {
  background: var(--title-text-color);
}
