.header {
  width: 100%;
}

.content_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;

  margin: 0 auto;
  padding: 24px 32px;
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  margin-right: 12px;
  border-radius: 100%;
}

.logo_description {
  color: var(--default-text-color);
  font-weight: 500;
  font-size: 14px;
  font-family: var(--default-font-family);
  font-style: normal;
  line-height: 16px;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 32px;
}

.counters {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.counter {
  padding-right: 32px;
  color: var(--accent-text-color);
  font-weight: bold;
  font-size: 14px;

  font-family: var(--default-font-family);
  font-style: normal;
  line-height: 16px;

  text-align: center;
}

.con_wal_btn {
  min-width: 160px;
  max-width: 160px;
  padding: 12px 16px;
}

@media (max-width: 57rem) {
  .logo_description {
    display: none;
  }
}

@media (max-width: 48rem) {
  .content_container {
    flex-direction: column;
  }

  .counter {
    padding: 0 32px;
  }

  .right {
    padding-top: 32px;
    padding-left: 0;
  }

  .con_wal_btn {
    margin: 0 32px;
  }
}

@media (max-width: 32rem) {
  .right {
    flex-direction: column;
    padding: 32px 0 0;
  }

  .counter {
    padding-bottom: 32px;
  }

  .content_container {
    padding-bottom: 0;
  }
}
