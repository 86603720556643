:root {
  --default-font-family: 'Montserrat', sans-serif;
  --background-color: #161516;
  --accent-gradient: linear-gradient(to bottom, #f36b6c, #b16bec);
  --default-text-color: #cdcdcd;
  --accent-text-color: #d24cfb;
  --title-text-color: #e8eaed;
  --section-border-color: #535353;
  --content-container-max-width: 75rem;

  font-size: 16px; /* for using rem unit */
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  color: var(--default-text-color);
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Remove scroll on externally imported modal open */
.ReactModal__Body--open {
  overflow: hidden;
}
