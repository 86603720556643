.content_container {

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;

  margin: 0 auto;
  padding: 0 32px 24px 32px;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  padding: 32px 32px 32px 0;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 32px;
  font-weight: bold;
}

.title span {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 48px;
  font-family: DM Sans, serif;
  font-style: normal;
  line-height: 64px;
  background-color: transparent;
  background-image: linear-gradient(to bottom, #f36b6c, #b16bec);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.description, .faq_link_wrapper {
  max-width: 520px;
  color: var(--default-text-color);
  font-weight: normal;
  font-size: 16px;

  font-family: var(--default-font-family);
  font-style: normal;
  line-height: 26px;
}

.faq_link_wrapper {
  margin-top: 20px;
}

.opensea_href, .faq_link {
  color: #da4dfc;
  font-weight: bold;
  font-size: 16px;

  font-family: var(--default-font-family);
  font-style: normal;
  line-height: 26px;

  text-decoration-line: none;
}

.btn_box {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 40px;
}

.get_gutter_btn {
  min-width: 200px;
  margin-right: 32px;

  padding: 16px 24px;
  color: #e8eaed;
  font-weight: bold;
  font-size: 16px;
  font-family: var(--default-font-family);
  font-style: normal;
  line-height: 16px;
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  padding: 32px 0 32px 32px;
}

.card_img {
  width: 100%;
  max-width: 512px;
  max-height: 512px;
  border-radius: 20px;
}

@media (max-width: 57rem) {
  .content_container {
    flex-direction: column;
  }

  .left {
    align-items: center;
    padding-right: 0;
  }

  .right {
    justify-content: center;
    padding-left: 0;
  }
}

@media (max-width: 37rem) {
  .btn_box {
    flex-direction: column;
  }

  .get_gutter_btn {
    margin: 16px 0 0 0;
  }
}
