.info_text {
  align-self: flex-start;
  color: #cdcdcd;

  font-family: var(--default-font-family);
}

.info_link {
  align-self: flex-start;
  margin: 16px 0;
  color: #b16bec;
  font-family: var(--default-font-family);
}

.styled_input {
  width: 100%;
  padding: 18px 12px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;

  font-family: var(--default-font-family);
  font-style: normal;
  line-height: 24px;
  border-radius: 6px;
}

.styled_input::placeholder {
  color: #9f9f9f;
}

.styled_button {
  width: 100%;
  margin-top: 32px;
  font-family: var(--default-font-family);
}
